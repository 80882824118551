import { Injectable } from '@angular/core'

import { ProductDetailDto, ProductDto, PageResultDto, FindProductDto, SetFileDto, ProductPopularDto, CreateOrUpdateProductDto, NewProductCollectionDto, UpdateNewProductCollectionAttachmentDto } from '@api/dto'

import { HttpService } from './http.service'
import { SetFilesDto } from '../dto/set-files.dto'

@Injectable()
export class ProductService {
  constructor(
    private httpService: HttpService) {
  }

  async publish(id: string) {
    return await this.httpService.post(`/merchant/v1/products/${id}/publish`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.post(`/merchant/v1/products/${id}/enable`)
    }
    
    return await this.httpService.post(`/merchant/v1/products/${id}/disable`)
  }

  async deleteOne(id: string) {
    return await this.httpService.delete(`/merchant/v1/products/${id}`)
  }

  async syncZohoItem() {
    return await this.httpService.post<Boolean>(`/merchant/v1/products/sync-zoho-items`)
  }

  async syncZohoItemImage() {
    return await this.httpService.post<Boolean>(`/merchant/v1/products/sync-zoho-items-images`)
  }

  async sync() {
    return await this.httpService.get<Boolean>(`/v1/localize-tech/sync-items`)
  }

  async find(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>('/merchant/v1/products', findProductDto)
  }

  async findProducts(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>('/v1/products', findProductDto)
  }

  async findOne(productId: string) {
    return await this.httpService.get<ProductDetailDto>(`/merchant/v1/products/${productId}`)
  }

  async setThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/thumbnail`, setFileDto)
  }

  async setColorThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/color-thumbnail`, setFileDto)
  }

  async setImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/images`, setFilesDto)
  }

  async setSpecsImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/specs-images`, setFilesDto)
  }

  async setProductPopular(productIds: string[]) {
    return await this.httpService.post<ProductDto>('/merchant/v1/products/popular', { productIds })
  }

  async findProductPopular() {
    return await this.httpService.get<ProductPopularDto[]>('/merchant/v1/products/popular')
  }

  async createOrUpdate(createOrUpdateProductDto: CreateOrUpdateProductDto[]) {
    return await this.httpService.put<ProductDto>(`/merchant/v1/products`, createOrUpdateProductDto)
  }

  async createProductChoices(productId: string, choiceGroupIds: string[]) {
    return await this.httpService.put<ProductDto>(`/merchant/v1/products/${productId}/choice`, { choiceGroupIds })
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<Boolean>(`/merchant/v1/products/import`, formData, config)
  }

  async findNewCollection() {
    return await this.httpService.get<NewProductCollectionDto>(`/merchant/v1/new-collection`)
  }

  async createNewCollection(productIds: string[]) {
    return await this.httpService.post<Boolean>(`/merchant/v1/new-collection`, { productIds })
  }

  async deleteNewCollection(productId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/new-collection/${productId}`)
  }

  async updateNewCollection(updateNewProductCollectionAttachmentDto: UpdateNewProductCollectionAttachmentDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/new-collection/attachment`, updateNewProductCollectionAttachmentDto)
  }
}