import { Injectable } from '@angular/core'

import { FindOrderDto, OrderDto, PageResultDto, OrderDetailDto, OrderStatus, OrderBadgeCountDto, CountStatusDto, OrderRejectDto } from '../dto'

import { HttpService } from './http.service'
import { ExportExcelResultDto } from '../dto/export-excel-result.dto'
import { DateFilterDto } from '../dto/date-filter.dto'

@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/merchant/v1/orders', findOrderDto)
  }

  async count() {
    return await this.httpService.get<CountStatusDto>('/merchant/v1/orders/count')
  }

  async exportExcel(dateFilterDto: DateFilterDto) {
    return await this.httpService.get<ExportExcelResultDto>(`/merchant/v1/orders/export`, dateFilterDto)
  }

  async findOne(orderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/merchant/v1/orders/${orderNumber}`)
  }

  async seen( status: OrderStatus) {
    return await this.httpService.put(`/merchant/v1/orders/seen/${status}`)
  }

  async unseen() {
    return await this.httpService.get<OrderBadgeCountDto>('/merchant/v1/orders/unseen-count')
  }

  async accept(orderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${orderNumber}/accept`)
  }

  async reject(orderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/merchant/v1/orders/${orderNumber}/reject`, { reason })
  }

  async shipping(orderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${orderNumber}/shipping`)
  }

  async pickup(orderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${orderNumber}/pickup`)
  }

  async complete(orderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${orderNumber}/complete`)
  }
}
