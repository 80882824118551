import { Injectable } from '@angular/core'

import { ChoiceGroupDto, ChoiceGroupItemsDto, UpdateChoiceGroupDto, UpdateChoiceItemDto } from '@api/dto/choice-group.dto'

import { HttpService } from './http.service'

@Injectable()
export class ChoiceGroupSerivce {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<ChoiceGroupDto[]>(`/merchant/v1/choice-group`)
  }

  async deleteChoice(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/choice-group/${id}`)
  }

  async createChoice(createChoiceDto: ChoiceGroupDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/choice-group`, createChoiceDto)
  }

  async sortOrder(itemIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/choice-group/sort-order`, { itemIds })
  }
  
  async sortOrderItem(id: string, itemIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/choice-group/${id}/items/sort-order`, {itemIds})
  }

  async createItem(id: string, createItem: ChoiceGroupItemsDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/choice-group/${id}/items`, createItem)
  }

  async findOne(id: string) {
    return await this.httpService.get<ChoiceGroupDto>(`/merchant/v1/choice-group/${id}`)
  }

  async update(id: string, updateChoiceGroupDto: UpdateChoiceGroupDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/choice-group/${id}`, updateChoiceGroupDto)
  }

  async updateItem(choiceId: string, itemId: string, updateItems: UpdateChoiceItemDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/choice-group/${choiceId}/items/${itemId}`, updateItems)
  }

  async deleteItem(choiceId: string, itemId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/choice-group/${choiceId}/items/${itemId}`)
  }

}
