import { Injectable } from '@angular/core'

import { AdminCreateCategoryDto, AdminUpdateCategoryDto, CategoryDto, PageResultDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class CategoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<CategoryDto[]>('/merchant/v1/categories')
  }

  async findOne(id: string) {
    return await this.httpService.get<CategoryDto>(`/merchant/v1/categories/${id}`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/categories/${id}`)
  }

  async create(createCategoryDto: AdminCreateCategoryDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/categories`, createCategoryDto)
  }

  async syncZohoCategory() {
    return await this.httpService.post<Boolean>(`/merchant/v1/categories/sync-zoho-category`)
  }

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/categories/sort-order', { categoryIds })
  }

  async update(id: string, updateCategoryDto: AdminUpdateCategoryDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/categories/${id}`, updateCategoryDto)
  }

  async findFeatured() {
    return await this.httpService.get<CategoryDto[]>('/merchant/v1/categories/featured')
  }

  async deleteFeatured(id: string) {
    return await this.httpService.delete<boolean>(`/merchant/v1/categories/featured/${id}`)
  }

  async createFeatured(categoryId: string) {
    return await this.httpService.post<boolean>('/merchant/v1/categories/featured', { categoryId })
  }

  async updateFeatured(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/merchant/v1/categories/featured/${id}`, { fileId })
  }

  async setOrder(categoryIds: string[]) {
    return await this.httpService.put<boolean>('/merchant/v1/categories/featured/sort-order', { categoryIds })
  }

  async findWithParent(searchText: string) {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/merchant/v1/categories/parent', {searchText})
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<Boolean>(`/merchant/v1/categories/import`, formData, config)
  }

}
