import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageResultDto } from '../dto'
import { AdminFaqSectionDto } from '@api/dto/admin-faq-section.dto'
@Injectable()
export class AdminFaqSectionService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return this.httpService.get<PageResultDto<AdminFaqSectionDto>>('/admin/v1/faqs/categories')
  }

  async create({ name, enabled }: AdminFaqSectionDto) {
    return this.httpService.post<PageResultDto<AdminFaqSectionDto>>('/admin/v1/faqs/categories', {name, enabled})
  }

  async update(id: string, { name, enabled }: AdminFaqSectionDto) {
    return this.httpService.put<PageResultDto<AdminFaqSectionDto>>('/admin/v1/faqs/categories', {name, enabled}, { id })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/admin/v1/faqs/categories/${id}/enable`)
    }
    
    return await this.httpService.put<Boolean>(`/admin/v1/faqs/categories/${id}/disable`)
  }

  async setFaqSection(categoryIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/faqs/categories/reorder', { categoryIds })
  }

  async archive(id: string) {
    return this.httpService.delete<Boolean>(`/admin/v1/faqs/categories/${id}`)
  }
}
