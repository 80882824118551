import { Injectable } from '@angular/core'

import { MasterTenantConfigurationDto } from '../master/dto'

import { HttpService } from './http.service'

@Injectable()
export class TenantConfigurationService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<MasterTenantConfigurationDto[]>('/merchant/v1/tenant-configuration')
  }

  async findOne(name: string) {
    return await this.httpService.get<MasterTenantConfigurationDto>(`/merchant/v1/tenant-configuration/${name}`)
  }
}
