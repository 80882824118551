import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { DateFilterDto } from '@api/dto/date-filter.dto'
import { AdminDashboardDto } from '@api/dto/admin-dashboard.dto'
import { AdminResultDto } from '../dto'
@Injectable()
export class AdminDashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(dateFilterDto?: DateFilterDto) {
    return this.httpService.get<AdminDashboardDto>('/admin/v1/dashboard', dateFilterDto)
  }

  async exportExcel(dateFilterDto?: DateFilterDto) {
    return this.httpService.get<AdminResultDto>('/admin/v1/dashboard/export',dateFilterDto)
  }
}
