import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageDto } from '../dto/page.dto'

import { PageResultDto, FindPageDto } from '../dto'
@Injectable()
export class AdminPageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(FindPageDto: FindPageDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/v1/pages', FindPageDto)
  }

  async findOne(pageId: string) {
    return await this.httpService.get<PageDto>(`/admin/v1/pages/${pageId}`)
  }

  async updateImage(pageId: string,imageId: string) {
    return await this.httpService.put(`/admin/v1/pages/${pageId}/image`, { "fileId": imageId })
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/v1/pages/${pageId}`, updatePageDto)
  }
}
