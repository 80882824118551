import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { AdminCategoryDto, AdminFeaturedCategoryDto, PageResultDto } from '@api/dto'

@Injectable()
export class AdminFeaturedCategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/categories/featured/reorder', { categoryIds })
  }

  async create({ categoryId, enabled }: AdminFeaturedCategoryDto) {
    return await this.httpService.post<Boolean>('/admin/v1/categories/featured', {
      categoryId,
      enabled
    })
  }

  async find() {
    return await this.httpService.get<PageResultDto<AdminCategoryDto>>('/admin/v1/categories/featured')
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/categories/featured/${id}`)
  }
}