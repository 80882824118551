import { Injectable } from '@angular/core'
import { AdminBannerDetailDto, AdminBannerDto, AdminBannerItemDto, AdminCreateUpdateBanner, CreateFeaturedBannerDto, FeaturedBannerDto, ResultDto, UpdateAdminBannerDto } from '../dto'

import { HttpService } from './http.service'


@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<AdminBannerDto[]>('/merchant/v1/banners')
  }

  async findOneBanner(bannerId: string) {
    return this.httpService.get<AdminBannerDetailDto>(`/merchant/v1/banners/${bannerId}`)
  }

  async updateBanner(bannerId: string, bannerItemId: string, updatePageDto: UpdateAdminBannerDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/banners/${bannerId}/items/${bannerItemId}/enable`, updatePageDto)
  }

  async fineOneBannerItem(bannerId: string, bannerItemId: string) {
    return await this.httpService.get<AdminBannerItemDto>(`/merchant/v1/banners/${bannerId}/items/${bannerItemId}`)
  }
  
  async createItemBanner(bannerId: string, adminCreateUpdateBanner: Partial<AdminCreateUpdateBanner>)  {
    return this.httpService.post<ResultDto>(`/merchant/v1/banners/${bannerId}/items`, adminCreateUpdateBanner)
  }

  async UpdateBannerItem(bannerId: string, bannerItemId: string, adminCreateUpdateBanner: Partial<AdminCreateUpdateBanner>) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/banners/${bannerId}/items/${bannerItemId}`, adminCreateUpdateBanner)
  }

  async deleteBannerItem(bannerId: string, bannerItemId: string) {
    return this.httpService.delete(`/merchant/v1/banners/${bannerId}/items/${bannerItemId}`)
  }

  async findFeatured() {
    return await this.httpService.get<FeaturedBannerDto[]>(`/merchant/v1/featured-banners`)
  }

  async findProductFeatured() {
    return await this.httpService.get<FeaturedBannerDto>(`/merchant/v1/featured-banners/product-featured-banner`)
  }

  async findOneFeatured(id: string) {
    return await this.httpService.get<FeaturedBannerDto>(`/merchant/v1/featured-banners/${id}`)
  }

  async createFeaturedBanner(createFeaturedBannerDto: CreateFeaturedBannerDto) {
    return await this.httpService.post<FeaturedBannerDto>(`/merchant/v1/featured-banners`, createFeaturedBannerDto)
  }

  async updateFeaturedBanner(id: string, updateFeaturedBannerDto: CreateFeaturedBannerDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/featured-banners/${id}`, updateFeaturedBannerDto)
  }

  async updateSortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/featured-banners/sort-order`, { ids })
  }

  async deleteFeaturedBanner(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/featured-banners/${id}`)
  }
}
