import { Injectable } from '@angular/core'

import { PageResultDto, FindProductInquiryDto, InquiryBadgeCountDto, ProductInquiryDto } from '@api/dto'

import { HttpService } from './http.service'
@Injectable()
export class ProductQuestionService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findProductInquiryDto: FindProductInquiryDto) {
    return await this.httpService.get<PageResultDto<ProductInquiryDto>>('/merchant/v1/product-questions', findProductInquiryDto )
  }

  async respond(inpuiryId: string, response: string) {
    return await this.httpService.patch<boolean>(`/merchant/v1/product-questions/${inpuiryId}`, { response })
  }

  async seen() {
    return await this.httpService.put('/merchant/v1/product-questions/seen')
  }

  async unseenCount() {
    return await this.httpService.get<InquiryBadgeCountDto>('/merchant/v1/product-questions/unseen-count')
  }
}
