import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FilterDetailDto, FilterDto, UpdateFilterDto } from '../dto'

@Injectable()
export class FilterService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<FilterDto[]>('/merchant/v1/filters')
  }

  async findOne(id: string) {
    return await this.httpService.get<FilterDetailDto>(`/merchant/v1/filters/${id}`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/merchant/v1/filters/${id}/enable`)  
    }
    
    return await this.httpService.put<Boolean>(`/merchant/v1/filters/${id}/disable`)
  }

  async deleteItem(id: string, itemId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/filters/${id}/items/${itemId}`)
  }

  async updateItem(id: string, itemId: string, filterItem: UpdateFilterDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/filters/${id}/items/${itemId}`, filterItem)
  }
}
