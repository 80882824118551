import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { SettingDto } from '../dto'

@Injectable()
export class AdminSettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<SettingDto[]>('/admin/v1/settings')
  }

  async update(id: string, settingDto: SettingDto) {
    return await this.httpService.put<SettingDto>(`/admin/v1/settings/${id}`, settingDto)
  }
}
