import { Injectable } from '@angular/core'

import { InquiryDto,  PageResultDto, InquiryDetailDto, FindInquiryDto, InquiryBadgeCountDto, ExportInquiryResultDto } from '@api/dto'

import { HttpService } from './http.service'
@Injectable()
export class InquiryService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findInquiryDto: FindInquiryDto) {
    return await this.httpService.get<PageResultDto<InquiryDto>>('/merchant/v1/inquiries', findInquiryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<InquiryDetailDto>(`/merchant/v1/inquiries/${id}`)
  }

  async seen() {
    return await this.httpService.put<boolean>(`/merchant/v1/inquiries/seen`)
  }

  async unseenCount() {
    return await this.httpService.get<InquiryBadgeCountDto>(`/merchant/v1/inquiries/unseen-count`)
  }

  async printInvoice(id: string) {
    return await this.httpService.get<ExportInquiryResultDto>(`/merchant/v1/inquiries/${id}/print-invoice`)
  }
}
