import { Injectable } from '@angular/core'

import { BrandDto, PageResultDto, FindAdminUser } from '@api/dto'

import { HttpService } from './http.service'
import { AdminUserDto } from '../dto/admin-user.dto'

@Injectable()
export class AdminUserService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findAdminUser: FindAdminUser) {
    return await this.httpService.get<PageResultDto<AdminUserDto>>('/admin/v1/users', findAdminUser)
  }

  async findOne(userId: string) {
    return await this.httpService.get<AdminUserDto>(`/admin/v1/users/${userId}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/v1/users/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/v1/users/${id}/disable`)
  }
}
