import { Injectable } from '@angular/core'
import { AdminBannerDetailDto, AdminBannerDto, AdminBannerItemDto, AdminCreateUpdateBanner, ResultDto, UpdateAdminBannerDto } from '../dto'

import { HttpService } from './http.service'


@Injectable()
export class AdminBannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<AdminBannerDto[]>('/admin/v1/banners')
  }

  async findOneBanner(bannerId: string) {
    return this.httpService.get<AdminBannerDetailDto>(`/admin/v1/banners/${bannerId}`)
  }

  async updateBanner(bannerId: string, bannerItemId: string, updatePageDto: UpdateAdminBannerDto) {
    return await this.httpService.put<ResultDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}/enable`, updatePageDto)
  }

  async fineOneBannerItem(bannerId: string, bannerItemId: string) {
    return await this.httpService.get<AdminBannerItemDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`)
  }
  
  async createItemBanner(bannerId: string, { title, imageId, enabled, content }: Partial<AdminCreateUpdateBanner>)  {
    return this.httpService.post<ResultDto>(`/admin/v1/banners/${bannerId}/items`, { 
      title,
      imageId,
      enabled,
      content
    })
  }

  async UpdateBannerItem(bannerId: string, bannerItemId: string, adminCreateUpdateBanner: AdminCreateUpdateBanner) {
    return await this.httpService.put<ResultDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`, adminCreateUpdateBanner)
  }

  async deleteBannerItem(bannerId: string, bannerItemId: string) {
    return this.httpService.delete(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`)
  }
}
