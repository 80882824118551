import { Injectable } from '@angular/core'
import { TenantDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class TenantService {
  constructor(
    private httpService: HttpService) {
  }

  async findById(id: string) {
    return await this.httpService.get<TenantDto>(`/v1/tenants/${id}`)
  }

  async findOne() {
    return await this.httpService.get<TenantDto>('/v1/tenants')
  }
}
