import { Injectable } from '@angular/core'

import { AdminShopDto, PageResultDto, AdminShopDetailDto, AdminUpdateShopDto, ResultDto, AdminCreateShopDto, AdminShopFinancialDto, AdminResultDto, FindProductDto, AdminProductDto, AdminCreateReasonDto, ReasonDto, AdminFindShopDto, ProductShippingRateDto } from '@api/dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'
import { DateFilterDto } from '@api/dto/date-filter.dto'
import { AdminShopProductDetailDto } from '@api/dto/admin-shop-product-detail'

@Injectable()
export class AdminShopService {
  constructor(
    private httpService: HttpService,
    private fileService:FileService) {
  }

  async find({ searchText, offset, limit }:AdminFindShopDto) {
    return await this.httpService.get<PageResultDto<AdminShopDto>>('/admin/v1/shops', { searchText, offset, limit })
  }

  async findOne(shopId: string) {
    return await this.httpService.get<AdminShopDetailDto>(`/admin/v1/shops/${shopId}`)
  }

  async update(shopId: string, { name, enabled, note, description }: Partial<AdminUpdateShopDto>)  {
    return await this.httpService.patch<ResultDto>(`/admin/v1/shops/${shopId}`, { name, enabled, note, description })
  }

  async create({ shopName, fullName, note, description, emailShop, phoneNumber, password, enabled }: Partial<AdminCreateShopDto>)  {
    return await this.httpService.post<ResultDto>('/admin/v1/shops/', { 
      shopName, 
      enabled,
      fullName, 
      note, 
      description,
      emailShop, 
      phoneNumber, 
      password 
    })
  }

  async setEnabledShipping(id: string, enabled: string) {
    if (enabled) {
      return await this.httpService.put<boolean>(`/admin/v1/shops/${id}/shipping/enable`)
    }
    
    return await this.httpService.put<boolean>(`/admin/v1/shops/${id}/shipping/disable`)
  }

  async findShopFinancial(shopId: string, dateFilter?: DateFilterDto ) {
    return await this.httpService.get<AdminShopFinancialDto>(`/admin/v1/shops/${shopId}/financial`, dateFilter)
  }

  async findProduct(shopId: string, findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<AdminProductDto>>(`/admin/v1/shops/${shopId}/products`, findProductDto)
  }

  async exportExcel(shopId: string, dateFilterDto?: DateFilterDto) {
    return await this.httpService.get<AdminResultDto>(`/admin/v1/shops/${shopId}/financial/export`,dateFilterDto)
  }

  async findOneProduct(shopId: string, productId: string) {
    return await this.httpService.get<AdminShopProductDetailDto>(`/admin/v1/shops/${shopId}/products/${productId}`) 
  }

  async findReasons(shopId: string, productId: string) {
    return await this.httpService.get<ReasonDto[]>(`/admin/v1/shops/${shopId}/products/${productId}/reasons`)
  }

  async createReason(shopId: string, productId: string, { status, reason }: AdminCreateReasonDto) {
    return await this.httpService.post<boolean>(`/admin/v1/shops/${shopId}/products/${productId}/reasons`, { status, reason })
  }

  async findProductShippingRate(shopId: string, productId: string, shippingProviderId: string) {
    return await this.httpService.get<ProductShippingRateDto[]>(`/admin/v1/shops/${shopId}/products/${productId}/product-shipping-rates`, { shippingProviderId })
  }

  async setEnabled(shopId: string, productId: string, adminEnabled: boolean) {
    if (adminEnabled) {
      return await this.httpService.post(`/admin/v1/shops/${shopId}/products/${productId}/enable`)
    }
    
    return await this.httpService.post(`/admin/v1/shops/${shopId}/products/${productId}/disable`)
  }
}
