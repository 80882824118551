import { Injectable } from '@angular/core'
import { AdminOrderDto, CountStatusDto, FindOrderDto, PageResultDto } from '../dto'
import { AdminOrderDetailDto } from '../dto/admin-order-detail.dto'
import { DateFilterDto } from '../dto/date-filter.dto'
import { ExportExcelResultDto } from '../dto/export-excel-result.dto'


import { HttpService } from './http.service'


@Injectable()
export class AdminOrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return this.httpService.get<PageResultDto<AdminOrderDto>>('/admin/v1/order', findOrderDto)
  }

  async exportExcel(dateFilterDto: DateFilterDto) {
    return this.httpService.get<ExportExcelResultDto>(`/admin/v1/order/export`, dateFilterDto)
  }

  async findOne(orderNumber: string) {
    return this.httpService.get<AdminOrderDetailDto>(`/admin/v1/order/${orderNumber}`)
  }

  async countStatus() {
    return this.httpService.get<CountStatusDto>('/admin/v1/order/statusCount')
  }
}
