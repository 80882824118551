import { Injectable } from '@angular/core'

import { ShopDto, ShopBankDto } from '@api/dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'
@Injectable()
export class ShopService {
  constructor(
    private httpService: HttpService,
    private fileService:FileService) {
  }

  async findOne() {
    return await this.httpService.get<ShopDto>('/merchant/v1/shop')
  }

  async findBank() {
    return await this.httpService.get<ShopBankDto>('/merchant/v1/shop/bank')
  }

  async update(shop: ShopDto) {
    return await this.httpService.put('/merchant/v1/shop', shop)
  }

  async updateBank(shop :ShopBankDto) {
    return await this.httpService.put('/merchant/v1/shop/bank',shop)
  }

  async updateCover(coverId: any) {
    return await this.httpService.put('/merchant/v1/shop/cover', { "fileId": coverId })
  }

  async updateLogo(logoId: any) {
    return await this.httpService.put('/merchant/v1/shop/logo', { "fileId": logoId })
  }
}
