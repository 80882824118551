import { Injectable } from '@angular/core'
import { AdminShopDto, AdminShopSetupDto, PageResultDto, ResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'

import { HttpService } from './http.service'


@Injectable()
export class AdminShopSetupService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ offset, limit }: PagingDto) {
    return this.httpService.get<PageResultDto<AdminShopDto>>('/admin/v1/shops/setup', { offset, limit })
  }

  async approve(shopSetupId: string) {
    return this.httpService.post<ResultDto>(`/admin/v1/shops/setup/approve/${shopSetupId}`)
  }

  async findOne(shopSetupId: string) {
    return this.httpService.get<AdminShopSetupDto>(`/admin/v1/shops/setup/${shopSetupId}`)
  }

  async update(shopId: string, { shopName, ownerName, email, phoneNumber }: AdminShopSetupDto)  {
    return this.httpService.put<ResultDto>(`/admin/v1/shops/setup/${shopId}`, { shopName, ownerName, email, phoneNumber })
  }

}
