import { CommonModule, CurrencyPipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { 
  HttpService, 
  AuthService, 
  LocalStorageService, 
  FileService, 
  UserService, 
  ProfileService, 
  SettingService, 
  CategoryService,
  AdminShopService,
  AdminBrandService,
  AdminBannerService,
  AdminCategoryService,
  WindowRef,
  AdminShopSetupService,
  AdminOrderService,
  AdminShippingService,
  AdminUserService,
  AdminDashboardService,
  DashboardService,
  PageService,
  AdminFaqService,
  AdminFaqSectionService,
  BrandService,
  InventoryService,
  ProductQuestionService,
  FinancialService,
  ShopService,
  OrderService,
  ProductService,
  ShippingService,
  TenantService,
  InquiryService,
  FilterService,
  DiscountProductCollectionService,
  BannerService,
  TenantConfigurationService,
  AdvertisementService,
  AdminPageService,
  AdminSettingService,
  CollectionService,
  ChoiceGroupSerivce,
  DeliveryFeeService
} from '@api/services'

import { ApiModuleOptions } from './api.module-options'
import { DragulaService } from 'ng2-dragula'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    UserService,
    HttpService,
    FileService,
    CategoryService,
    BrandService,
    AuthService,
    AuthStore,
    AuthQuery,
    ShippingService,
    ProductService,
    DragulaService,
    AdminPageService,
    OrderService,
    SettingService,
    FilterService,
    ShopService,
    ChoiceGroupSerivce,
    FinancialService,
    DiscountProductCollectionService,
    CollectionService,
    AdminShopService,
    ProfileService,
    AdminBrandService,
    AdminBannerService,
    AdminShippingService,
    AdminCategoryService,
    ProductQuestionService,
    AdminSettingService,
    ShopService,
    AdminUserService,
    InquiryService,
    BannerService,
    AdminDashboardService,
    WindowRef,
    DashboardService,
    TenantConfigurationService,
    InventoryService,
    AdminOrderService,
    AdminShopSetupService,
    DeliveryFeeService,
    AdvertisementService,
    BrandService,
    TenantService,
    CurrencyPipe,
    WindowRef,
    PageService,
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
