import { Injectable } from '@angular/core'
import { AdminCreateBrandDto, AdminFilerBrandDto, BrandDto, PageResultDto, ResultDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminBrandService {
  constructor(
    private httpService: HttpService) {
  }

  async find(adminFilerBrandDto: AdminFilerBrandDto) {
    return await this.httpService.get<PageResultDto<BrandDto>>('/admin/v1/brand', adminFilerBrandDto)
  }

  async findOne(BrandId: string) {
    return this.httpService.get<BrandDto>(`/admin/v1/brand/${BrandId}`)
  }
  
  async create({ name }: Partial<AdminCreateBrandDto>)  {
    return this.httpService.post<ResultDto>('/admin/v1/brand', { 
      name
    })
  }

  async setCategoires(BrandId: string, categoryIds: string[]) {
    return await this.httpService.put(`/admin/v1/brand/${BrandId}/categories`, { categoryIds })
  }
  
  async deleteById(BrandId: string) {
    return this.httpService.delete(`/admin/v1/brand/${BrandId}`)
  }

  async setApproval(BrandId: string) {
    return await this.httpService.put(`/admin/v1/brand/${BrandId}/accept`)
  }

  async updateImage(BrandId: string, imageId: string) {
    return await this.httpService.put(`/admin/v1/brand/${BrandId}/image`, { "fileId": imageId })
  }

  async updateLogo(BrandId: string, imageId: string) {
    return await this.httpService.put(`/admin/v1/brand/${BrandId}/logo`, { "fileId": imageId })
  }

  async update(BrandId: string, updatePageDto: BrandDto) {
    return await this.httpService.put<BrandDto>(`/admin/v1/brand/${BrandId}`, updatePageDto)
  }

}
