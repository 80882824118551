import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { shareReplay } from 'rxjs/operators'

import { environment } from './../../../environments/environment'
import { LOCAL_STORAGE_SESSION_TOKEN_KEY, HTTP_HEADER_SESSION_TOKEN_KEY, LOCAL_STORAGE_TENANT_ID_KEY, HTTP_HEADER_TENANT_ID_KEY, LOCAL_STORAGE_TENANT_CONFIG_KEY } from '../core/constants'
import { ApiModuleOptions } from '../core/api.module-options'

import { LocalStorageService } from './local-storage.service'
import { MasterTenantConfigurationDto, MasterTenantDto } from '@api/master/dto'

@Injectable()
export class HttpService {
  constructor(
    private localStorage: LocalStorageService,
    private httpClient: HttpClient,
    private options: ApiModuleOptions) {

  }
  
  // ===============================================================================================
  // Sessions
  // ===============================================================================================

  async removeSessionToken() {
    delete window['_sessionToken']
    
    return await this.localStorage.remove(LOCAL_STORAGE_SESSION_TOKEN_KEY)
  }

  async getSessionToken() {
    return window['_sessionToken'] || await this.localStorage.get<string>(LOCAL_STORAGE_SESSION_TOKEN_KEY)
  }

  async setSessionToken(sessionToken: string) {
    window['_sessionToken'] = sessionToken
    
    await this.localStorage.set(LOCAL_STORAGE_SESSION_TOKEN_KEY, sessionToken)
  }

  async getTenantId() {
    return await this.localStorage.get<string>(LOCAL_STORAGE_TENANT_ID_KEY)
  }

  async setTenantId(tenantId: string) {
    await this.localStorage.set(LOCAL_STORAGE_TENANT_ID_KEY, tenantId)
  }

  async getTenantConfig() {
    return await this.localStorage.get<MasterTenantConfigurationDto[]>(LOCAL_STORAGE_TENANT_CONFIG_KEY)
  }

  async setTenantConfig(tenantConfigurations: MasterTenantConfigurationDto[]) {
    await this.localStorage.set(LOCAL_STORAGE_TENANT_CONFIG_KEY, tenantConfigurations)
  }

  // ===============================================================================================
  // Helpers
  // ===============================================================================================

  async createHeaders(customHeaders: HttpHeaders) {
    const sessionToken = await this.getSessionToken()
    const tenantId = await this.getTenantId()
    let headers = customHeaders || new HttpHeaders()

    if (sessionToken) {
      headers = headers.set(HTTP_HEADER_SESSION_TOKEN_KEY, sessionToken)
    }

    document.domain = window.location.hostname
    
    if (window.opener && window.opener['tenantPreview']) {
      headers = headers.set(HTTP_HEADER_TENANT_ID_KEY, window.opener['tenantPreview'].id)
    } else if (tenantId) {
      headers = headers.set(HTTP_HEADER_TENANT_ID_KEY, tenantId)
    }

    return headers
  }

  public get serverURL() {
    return this.options.serverURL
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async get<T>(path: string, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    const headers = await this.createHeaders(customHeaders)

    return this.httpClient.get<T>(fullPath, {params: queryParams, headers}).toPromise()
  }

  async post<T>(path: string, body?: any, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    const headers = await this.createHeaders(customHeaders)
    
    return this.httpClient.post<T>(fullPath, body, {params: queryParams, headers}).toPromise()
  }

  async put<T>(path: string, body?: any, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    const headers = await this.createHeaders(customHeaders)

    return this.httpClient.put<T>(fullPath, body, {params: queryParams, headers}).toPromise()
  }

  postWithProgress(path: string, body?: any, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    
    return this.httpClient.post(fullPath, body, 
      {params: queryParams, reportProgress: true, observe: 'events', headers: customHeaders}).pipe(shareReplay(1))
  }

  async patch<T>(path: string, body?: any, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    const headers = await this.createHeaders(customHeaders)

    return this.httpClient.patch<T>(fullPath, body, {params: queryParams, headers}).toPromise()
  }

  async delete<T>(path: string, queryParams?: any, customHeaders?: HttpHeaders) {
    const fullPath = this.serverURL + path
    const headers = await this.createHeaders(customHeaders)

    return this.httpClient.delete<T>(fullPath, {params: queryParams, headers}).toPromise()
  }
}
