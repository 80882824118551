import { Injectable } from '@angular/core'

import { BrandDto, PageResultDto, ResultDto, MerchantFindBrandDto } from '@api/dto'

import { HttpService } from './http.service'
import { MerchantCreateBrand } from '../dto/merchanat-create-brand.dto'

@Injectable()
export class BrandService {
  constructor(
    private httpService: HttpService) {
  }
  
  async find(merchantFindBrandDto: MerchantFindBrandDto) {
    return await this.httpService.get<PageResultDto<BrandDto>>('/merchant/v1/brands', merchantFindBrandDto)
  }

  async findPopular() {
    return await this.httpService.get<BrandDto[]>('/merchant/v1/brands/popular')
  }

  async findOne(BrandId: string) {
    return await this.httpService.get<BrandDto>(`/merchant/v1/brands/${BrandId}`)
  }
  
  async create({ name }: Partial<MerchantCreateBrand>)  {
    return await this.httpService.post<ResultDto>('/merchant/v1/brands', { name })
  }

  async setPopular(brandId: string) {
    return await this.httpService.post<Boolean>('/merchant/v1/brands/popular', { brandId })
  }

  async setOrder(brandIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/brands/sort-order', { brandIds })
  }

  async setPopularOrder(brandIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/brands/popular/sort-order', { brandIds })
  }

  async deletePopular(brandId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/brands/popular/${brandId}`)
  }

  async deleteById(brandId: string) {
    return await this.httpService.delete(`/merchant/v1/brands/${brandId}`)
  }

  async updateImage(brandId: string, imageId: string) {
    return await this.httpService.put(`/merchant/v1/brands/${brandId}/image`, { "fileId": imageId })
  }

  async updateLogo(brandId: string, logoId: string) {
    return await this.httpService.put(`/merchant/v1/brands/${brandId}/logo`, { "fileId": logoId })
  }

  async update(brandId: string, updateBrandDto: BrandDto) {
    return await this.httpService.put<BrandDto>(`/merchant/v1/brands/${brandId}`, updateBrandDto)
  }
}
