import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, AdminUserDto, AdminCreateUserDto, AdminUpdateUserDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<AdminUserDto>>('/merchant/v1/users', findUserDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminUserDto>(`/merchant/v1/users/${id}`)
  }

  async create(createUserDto: AdminCreateUserDto) {
    return await this.httpService.post<UserDto>('/merchant/v1/users', createUserDto)
  }

  async update(id: string, updateUserDto: AdminUpdateUserDto) {
    return await this.httpService.put<UserDto>(`/merchant/v1/users/${id}`, updateUserDto)
  }

  async destroy(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/users/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/merchant/v1/users/${id}/enable`)
    }
    
    return await this.httpService.put(`/merchant/v1/users/${id}/disable`)
  }

  async updateNote(id: string, note: string) {
    return await this.httpService.patch<Boolean>(`/merchant/v1/users/${id}/note`, { defaultNote: note })
  }
}
