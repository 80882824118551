import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { DashboardDto } from '@api/dto/dashboard.dto'
import { DateFilterDto } from '@api/dto/date-filter.dto'
import { ExportExcelResultDto } from '@api/dto/export-excel-result.dto'
@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(dateFilterDto?: DateFilterDto) {
    return await this.httpService.get<DashboardDto>('/merchant/v1/dashboard', dateFilterDto)
  }

  async exportExcel(dateFilterDto?: DateFilterDto) {
    return await this.httpService.get<ExportExcelResultDto>(`/merchant/v1/dashboard/export`, dateFilterDto)
  }

}
