import { Injectable } from '@angular/core'

import { ProductDto, PageResultDto, CreateDiscountProductCollectionDto, UpdateDiscountProductCollectionDto, DiscountProductCollectionDetailDto, SetFileDto, DiscountProductCollectionDto } from '@api/dto'

import { HttpService } from './http.service'
import { SetFilesDto } from '../dto/set-files.dto'
import { PagingDto } from '../dto/paging.dto'

@Injectable()
export class DiscountProductCollectionService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<DiscountProductCollectionDto[]>(`/merchant/v1/discount-collection`)
  }

  async findOne(id: string) {
    return await this.httpService.get<DiscountProductCollectionDetailDto>(`/merchant/v1/discount-collection/${id}`)
  }

  async create(createDiscountProductCollectionDto: CreateDiscountProductCollectionDto) {
    return await this.httpService.post<DiscountProductCollectionDto>(`/merchant/v1/discount-collection`, createDiscountProductCollectionDto)
  }

  async update(id: string, updateDiscountProductCollectionDto: UpdateDiscountProductCollectionDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/discount-collection/${id}`, updateDiscountProductCollectionDto)
  }

  async updateItems(id: string, productIds: string[]) {
    return await this.httpService.post<Boolean>(`/merchant/v1/discount-collection/items`, { productDiscountCollectionId: id, productIds })
  }

  async updateBanner(id: string, setFileDto: SetFileDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/discount-collection/${id}/banner`, setFileDto)
  }

  async updateMobileBanner(id: string, setFileDto: SetFileDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/discount-collection/${id}/mobile-banner`, setFileDto)
  }

  async updateSortOrder(collectionIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/discount-collection/sort-order`, { collectionIds })
  }

  async updateItemSortOrder(id: string, productIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/discount-collection/${id}/items/sort-order`, { productIds })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/discount-collection/${id}`)
  }

  async deleteItem(id: string, productId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/discount-collection/${id}/items/${productId}`)
  }
}