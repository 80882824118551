import { Injectable } from '@angular/core'

import { AdminCategoryDto, PageResultDto, ResultDto, AdminUpdateCategoryDto, AdminCreateCategoryDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminCategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async find() {
    return await this.httpService.get<AdminCategoryDto[]>('/admin/v1/categories')
  }

  async findChildren(searchText: string) {
    return await this.httpService.get<PageResultDto<AdminCategoryDto>>('/admin/v1/categories/last-children', {searchText})
  }

  async findOne(categoryId: string) {
    return await this.httpService.get<AdminCategoryDto>(`/admin/v1/categories/${categoryId}`)
  }

  async update(categoryId: string, { name, iconImageId, bannerImageId }: AdminUpdateCategoryDto) {
    return await this.httpService.patch<ResultDto>(`/admin/v1/categories/${categoryId}`, {
      name,
      iconImageId,
      bannerImageId
    })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<ResultDto>(`/admin/v1/categories/${categoryId}`)
  }

  async updateSpecification(categoryId: string, { specifications }: any) {
    return await this.httpService.patch<ResultDto>(`/admin/v1/categories/${categoryId}/specifications`, {
      specifications
    })
  }

  async create({ name, iconImageId, bannerImageId, parentId  }: AdminCreateCategoryDto) {
    return await this.httpService.post<ResultDto>('/admin/v1/categories', {
      name, 
      iconImageId, 
      bannerImageId, 
      parentId
    })
  }
}