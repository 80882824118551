import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CollectionDetailDto, CollectionDto, CreateCollectionProductDto, ResultDto } from '../dto'

@Injectable()
export class CollectionService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<CollectionDto[]>('/merchant/v1/collections')
  }

  async setOrder(collectionIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/collections/sort-order', { collectionIds })
  }

  async findOne(id: string) {
    return await this.httpService.get<CollectionDetailDto>(`/merchant/v1/collections/${id}`)
  }

  async create({ name, url, button }: CollectionDto) {
    return await this.httpService.post<any>('/merchant/v1/collections', { name, url, button })
  }

  async productCollectionItem({ collectionId, productId }: CreateCollectionProductDto) {
    return await this.httpService.post(`/merchant/v1/collections/items`, { collectionId,  productId})
  }

  async update(id: string, { name, url, button }: CollectionDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/collections/${id}`, {
      name, url, button
    })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/merchant/v1/collections/${id}/enabled`)
    }
    
    return await this.httpService.put<Boolean>(`/merchant/v1/collections/${id}/disabled`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/collections/${id}`)
  }

  async updateImage(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/collections/${id}/thumbnail`, { fileId })
  }

  async updateMobileImage(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/collections/${id}/mobile-thumbnail`, { fileId })
  }

  async setOrderProduct(id: string, productIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/collections/${id}/items/sort-order`, { productIds })
  }

  async deleteItems(id: string, productId: string) {
    return await this.httpService.delete(`/merchant/v1/collections/${id}/items/${productId}`)
  }
}
