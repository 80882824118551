import { Injectable } from '@angular/core'

import { InventoryItemDto, PageResultDto } from '../dto'

import { HttpService } from './http.service'
import { SearchInventoryItemDto } from '../dto/search-inventory-item.dto'
import { UpdateInventoryItemDto } from '../dto/update-inventory-item.dto'

@Injectable()
export class InventoryService {
  constructor(
    private httpService: HttpService) {
  }

  async findItems(searchInventoryItemDto: SearchInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/items', searchInventoryItemDto)
  }

  async findLowStockItems(searchInventoryItemDto: SearchInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/low-stock-items', searchInventoryItemDto)
  }

  async updateItems(updateInventoryItemDto: UpdateInventoryItemDto) {
    return await this.httpService.put<boolean>('/merchant/v1/inventory/items', updateInventoryItemDto)
  }
}
