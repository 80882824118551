import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FinancialDto } from '../dto'

@Injectable()
export class FinancialService {
  constructor(
    private httpService: HttpService) {
  }

  async findOne() {
    return await this.httpService.get<FinancialDto>('/merchant/v1/financial')
  }
}
