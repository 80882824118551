

import { Injectable } from '@angular/core'
import { AdminShippingDeliveryOptionDto, AdminShippingProviderDto, AdminShippingZoneDetailDto, AdminShippingZoneDto, ResultDto, UpdateAdminShippingRateDto, UpdateAdminShippingZoneDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminShippingService {
  constructor(
    private httpService: HttpService) {
    
  }

  async find() {
    return await this.httpService.get<AdminShippingProviderDto[]>(`/admin/v1/shippings/providers`)
  }

  async update(id: string, { name, sortOrder }: AdminShippingProviderDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/shippings/providers`, { name, sortOrder }, { id })
  }

  // async providerDeliveryOption() {
  //   return await this.httpService.get<AdminShippingDeliveryOptionDto[]>(`/admin/v1/shippings/providers/deliverOption`)
  // }

  async zones(shippingProviderId: string) {
    return await this.httpService.get<AdminShippingZoneDto[]>(`/admin/v1/shippings/zones`, {
      shippingProviderId
    })
  }

  async zoneDetail(zoneId: string) {
    return await this.httpService.get<AdminShippingZoneDetailDto>(`/admin/v1/shippings/zones/${zoneId}`)
  }

  async updateRate(rateId: string, { cost }: UpdateAdminShippingRateDto) {
    return await this.httpService.patch<ResultDto>(`/admin/v1/shippings/rates/${rateId}`, {
      cost
    })
  }

  async updateZone(zoneId: string, { enabled, minDays, maxDays }: UpdateAdminShippingZoneDto) {
    return await this.httpService.patch<ResultDto>(`/admin/v1/shippings/zones/${zoneId}`, {
      enabled,
      minDays,
      maxDays
    })
  }

}
