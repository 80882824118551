
import { Injectable } from '@angular/core'

import { ShippingProfileDto, ShippingZoneDto, ShippingZoneListDto, ShippingZoneDetailDto, UpdateShippingZoneDto, ResultDto, CreateShippingRateDto, UpdateShippingRateDto, DistrictDto, CreateDistrictDto, UpdateDistrictDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class ShippingService {
  constructor(
    private httpService: HttpService) {
    
  }

  async masterProfiles() {
    return await this.httpService.get<ShippingProfileDto[]>('/shippings/profiles/masters')
  }

  async profile(profileId: string) {
    return await this.httpService.get<ShippingProfileDto>('/shippings/profiles/' + profileId)
  }

  async zone(profileId: string, zoneId: string) {
    return await this.httpService.get<ShippingZoneDto>('/shippings/profiles/' + profileId + '/zones/' + zoneId)
  }

  async zones() {
    return await this.httpService.get<ShippingZoneListDto[]>('/merchant/v1/shippings/zones')
  }

  async zoneDetail(zoneId: string) {
    return await this.httpService.get<ShippingZoneDetailDto>(`/merchant/v1/shippings/zones/${zoneId}`)
  }

  async updateZone(zoneId: string, { enabled, minDays, maxDays, providers }: UpdateShippingZoneDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/shippings/zones/${zoneId}`, { enabled, minDays, maxDays, providers })
  }

  async createRate({ min, cost, shippingZoneId }: CreateShippingRateDto) {
    return await this.httpService.post<ResultDto>('/merchant/v1/shippings/rates', { min, cost, shippingZoneId })
  }

  async updateRate(rateId: string, { cost }: UpdateShippingRateDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/shippings/rates/${rateId}`, { cost })
  }

  async deleteRate(rateId: string) {
    return await this.httpService.delete<ResultDto>(`/merchant/v1/shippings/rates/${rateId}`)
  }

  async findDistrict(city: string) {
    return await this.httpService.post<DistrictDto[]>('/merchant/v1/shippings/zones/find-district', { city })
  }

  async createDistrict(createDistrictDto: CreateDistrictDto) {
    return await this.httpService.post<Boolean>('/merchant/v1/shippings/zones/district', createDistrictDto)
  }

  async updateDistrict(updateDistrictDto: UpdateDistrictDto) {
    return await this.httpService.put<Boolean>('/merchant/v1/shippings/zones/district', updateDistrictDto)
  }

  async deleteDistrict(districtId: string) {
    return await this.httpService.post<Boolean>('/merchant/v1/shippings/zones/delete-district', { districtId })
  }
}
