import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageResultDto, AdminFaqDto, AdminFindFaqDto } from '../dto'

@Injectable()
export class AdminFaqService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ categoryId }: AdminFindFaqDto) {
    return this.httpService.get<PageResultDto<AdminFaqDto>>('/admin/v1/faqs', { categoryId })
  }

  async create({ answer, question, enabled, categoryId }: AdminFaqDto) {
    return this.httpService.post<PageResultDto<AdminFaqDto>>('/admin/v1/faqs', { question, answer, enabled, categoryId })
  }

  async update(id: string, { answer, question, enabled }: AdminFaqDto) {
    return this.httpService.put<PageResultDto<AdminFaqDto>>('/admin/v1/faqs', { question, answer, enabled }, {id})
  }

  async setEnabled(id: string, enabled: boolean) {
      if (enabled) {
        return await this.httpService.put<Boolean>(`/admin/v1/faqs/${id}/enable`)
      }
      
      return await this.httpService.put<Boolean>(`/admin/v1/faqs/${id}/disable`)
  }

  async archive(id: string) {
        return this.httpService.delete<Boolean>(`/admin/v1/faqs/${id}`)
  }

  async setFaq(faqIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/faqs/reorder', { faqIds })
  }
}
