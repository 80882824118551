import { Injectable } from '@angular/core'
import { AdvertisementDetailDto, AdvertisementDto, CreateAdvertisementDto, CreateAdvertisementItemDto, FindAdvertisementTypeDto, UpdateAdvertisementDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdvertisementService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<AdvertisementDto[]>('/merchant/v1/advertisements')
  }

  async findOne(id: string) {
    return this.httpService.get<AdvertisementDetailDto>(`/merchant/v1/advertisements/${id}`)
  }

  async update(id: string, { enabled, url }: Partial<UpdateAdvertisementDto>) {
    return await this.httpService.put<boolean>(`/merchant/v1/advertisements/${id}`, { enabled, url })
  }

  async create({ placement, url }: CreateAdvertisementDto) {
    return await this.httpService.post<boolean>(`/merchant/v1/advertisements`, { placement, url })
  }
  
  async createItem(id: string, { imageId, mobileImageId, enabled }: Partial<CreateAdvertisementItemDto>)  {
    return this.httpService.post<boolean>(`/merchant/v1/advertisements/${id}/items`, { imageId, enabled, mobileImageId })
  }

  async updateItem(id: string, itemId: string, updateAdvertisementDto: UpdateAdvertisementDto) {
    return await this.httpService.put<boolean>(`/merchant/v1/advertisements/${id}/items/${itemId}`, updateAdvertisementDto)
  }

  async deleteItem(id: string, itemId: string) {
    return this.httpService.delete(`/merchant/v1/advertisements/${id}/items/${itemId}`)
  }
}
